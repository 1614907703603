<template>
  <div class="about">

    <v-toolbar  dark color="primary">
      <v-toolbar-title><v-icon left>domain</v-icon>{{siteLocationCode}} : Bin location List</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-text-field
              v-model="search"
              append-icon="search"
              dark background-color="primary"
              clearable=true
              clear-icon="close"
              label="Search Site by code or name"
              single-line
              hide-details>
      </v-text-field> -->
      <v-text-field
                v-model="search"

                light solo
                clearable=true
                clear-icon="close"
                label="Search Bin"

                single-line
                hide-details
        ></v-text-field> <!-- append-outer-icon="search" -->
        <v-icon>search</v-icon>
      <v-btn text @click="createBinLocation()">
        <v-icon left>add_location</v-icon>
        Add Bin Location
      </v-btn>
      <v-btn text @click="getSpreadsheetTemplate(siteLocationCode)">
        <v-icon left>cloud_download</v-icon>
        excel
      </v-btn>
    </v-toolbar>


    <v-data-table
      :headers="headers"
      :items="binlocations"
      :search="search"
      :loading="loadingProgress"
      class="elevation-1"
      @click:row="editBinLocation">
      <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
      <template v-slot:item.action="{ item }">
        <v-icon @click.stop="confirmDeleteSelectedBinLocation(item)">delete</v-icon>
      </template>
    </v-data-table>

      <v-dialog v-model="binLocationEditModal" max-width="500px">
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title><v-icon left>edit_location</v-icon>Edit Bin Location Details</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="valid" ref="form" lazy-validation>
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-text-field name="locationCode"
                                label="Location Code"
                                v-model="binLocationEdited.locationCode"
                                readonly>
                  </v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field name="locationName"
                                label="Location Name"
                                v-model="binLocationEdited.locationName"
                                readonly>
                  </v-text-field>
                </v-flex>
              </v-layout>
              <v-text-field name="partName"
                              label="Part Name"
                              type="text"
                              v-model="binLocationEdited.partName"
                              readonly>
              </v-text-field>
                <v-text-field name="partCode"
                              label="Part Code"
                              v-model="binLocationEdited.partCode"
                              :rules="[rules.required]"
                              counter
                              maxlength="15">
                </v-text-field>

                <v-text-field name="binLocation"
                              label="Bin Location"
                              type="text"
                              v-model="binLocationEdited.binLocation"
                              :rules="[rules.required]"
                              counter
                              maxlength="25">
                </v-text-field>

            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn text small @click="cancelAndRefresh()">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click.prevent="onEditSubmit"
              :disabled="!valid"><v-icon left>save</v-icon>&nbsp;Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="binLocationCreateModal" max-width="500px">
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title><v-icon left>add_location</v-icon>Create Bin Location</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="createValid" ref="createForm" lazy-validation>

              <v-text-field name="locationCode"
                            label="Location Code"
                            v-model="binLocationEdited.locationCode"
                            readonly>
              </v-text-field>

              <v-text-field name="partCode"
                            label="Part Code"
                            v-model="binLocationEdited.partCode"
                            :rules="[rules.required]"
                            counter
                            maxlength="15">
              </v-text-field>

              <v-text-field name="binLocation"
                            label="Bin Location"
                            type="text"
                            v-model="binLocationEdited.binLocation"
                            :rules="[rules.required]"
                            counter
                            maxlength="25">
              </v-text-field>

            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn text small @click="cancelAndRefresh()">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click.prevent="onCreateSubmit"
              :disabled="!createValid"><v-icon left>save</v-icon>&nbsp;Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deleteConfirmationModal" persistent max-width="400px">
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title><v-icon large left>report</v-icon>Confirm operation</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            Do you really want to delete Bin Location?
          </v-card-text>
          <v-card-actions>
            <v-btn text small @click="deleteConfirmationModal=false">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click.stop="deleteSelectedBinLocation(); deleteConfirmationModal = false"><v-icon small left>thumb_up</v-icon>Yes</v-btn>
            <!--<v-btn color="primary" @click.stop="deleteConfirmationModal = false;"><v-icon small left>thumb_down</v-icon>No</v-btn>-->
          </v-card-actions>
        </v-card>
      </v-dialog>

  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations} from 'vuex'
import axios from 'axios'
import { API_URL } from '../../utils/api'

export default {


  beforeDestroy () {
      this.setBinlocations([])
  },


  data () {
    return {
      search: '',
      siteLocationCode: this.$route.params.locationCode,

      binLocationEditModal: false,
      binLocationCreateModal: false,
      valid: false,
      createValid: false,

      deleteConfirmationModal: false,
      binLocationToDelete: null,

      headers: [
          /*{ text: 'Site Code', align: 'left', value: 'partCode' },
          { text: 'Site Name', align: 'center', value: 'partName' },*/
          { text: 'Part Code', align: 'left', value: 'partCode' },
          { text: 'Part Name', align: 'center', value: 'partName' },
          { text: 'Return Control', align: 'center', value: 'returnControl' },
          { text: 'Current Stock Qty', align: 'center', value: 'currentStockQty' },
          { text: 'Bin Location', align: 'center', value: 'binLocation' },
          { text: 'Actions', value: "action", sortable: false }
      ],
      rules: {
          required: value => !!value || 'Required.'
      },
      binLocationEdited: {}
    }
  },
    methods: {
      ...mapActions(['fetchSite', 'deleteBinLocation', 'updateBinLocation']),
      ...mapMutations(['setBinlocations']),

      cancelAndRefresh() {
        this.binLocationCreateModal = false
        this.binLocationEditModal = false
        this.fetchSite(this.siteLocationCode)
      },
      getSpreadsheetTemplate: function(locationCode){
          var hiddenIFrameID = 'hiddenDownloader',
          iframe = document.getElementById(hiddenIFrameID);
          if (iframe === null) {
              iframe = document.createElement('iframe');
              iframe.id = hiddenIFrameID;
              iframe.style.display = 'none';
              document.body.appendChild(iframe);
          }
          iframe.src = 'data/excel/binlocations?locationCode='+locationCode;
      },
      editBinLocation( binLocation ){
          this.binLocationEditModal = true
          this.binLocationEdited = binLocation
      },
      createBinLocation(){
          this.binLocationCreateModal = true
          this.binLocationEdited = {locationCode:this.siteLocationCode}
      },
      onEditSubmit (/* event */) {
        if (this.$refs.form.validate()) {
            // TODO <AP later>: - dispatch action
            //const url = (this.account.id === undefined)?`${API_URL.newAccountApi}` : `${API_URL.accountApi}`
            const form = {
                    locationCode: this.binLocationEdited.locationCode,
                    partCode:     this.binLocationEdited.partCode,
                    binLocation:  this.binLocationEdited.binLocation
                  }
            this.updateBinLocation({form});
            this.binLocationEditModal = false;
        }//eo if (this.$refs.form.validate())
      },
      onCreateSubmit () {
        if (this.$refs.createForm.validate()) {
            // TODO <AP later>: - dispatch action
            //const url = (this.account.id === undefined)?`${API_URL.newAccountApi}` : `${API_URL.accountApi}`
            const form = {
                    locationCode: this.binLocationEdited.locationCode,
                    partCode:     this.binLocationEdited.partCode,
                    binLocation:  this.binLocationEdited.binLocation
                  }
            this.updateBinLocation({form});
            this.binLocationCreateModal = false;
        }//eo if (this.$refs.form.validate())
      },
      confirmDeleteSelectedBinLocation(binLocation){
          this.deleteConfirmationModal = true
          this.binLocationToDelete     = binLocation
      },
      deleteSelectedBinLocation(){
        this.deleteBinLocation({binLocation:this.binLocationToDelete, siteLocationCode:this.siteLocationCode})
      }
    },
  computed: mapGetters(['binlocations', 'loadingProgress'])

}
</script>
