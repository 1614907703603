<template>
  <div class="about">

    <v-toolbar dark color="primary">
      <v-toolbar-title>
        <v-icon>location_city</v-icon>&nbsp;Master List
      </v-toolbar-title>
    </v-toolbar>

    <v-data-table
        :headers="headers"
        :items="lookupParts"
        :loading="loadingProgress"
        class="elevation-1"
        @click:row="openWarehouseParts2">
      <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
    </v-data-table>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {

  data() {
    return {

      headers: [
        {text: 'Parts Code', align: 'left', value: 'pprtcd'},
        {text: 'Parts Name', align: 'center', value: 'pprtn1'},
        /*TODO S21 { text: 'Category', align: 'center', value: 'pcat' },*/
        {text: 'Product Code', align: 'center', value: 'pprdcd'},
        /*TODO S21 { text: 'Vendor Parts Code', align: 'center', value: 'pprtst' },*/
        /*TODO S21 { text: 'Multiple Control', align: 'center', value: 'multipleControl' },*/
        {text: 'Return Control', align: 'center', value: 'returnControl'}
      ]
    }
  },
  methods: {
    ...mapActions(['fetchWarehouseParts']),
    openWarehouseParts(partcode) {
      console.log('Open Warehouse Parts (' + partcode + ')')
      this.fetchWarehouseParts(partcode.toString())
    },
    openWarehouseParts2(item) {
      console.log('Open Warehouse Parts (' + item.pprtcd + ')')
      this.fetchWarehouseParts(item.pprtcd.toString())
    }
  },
  computed: mapGetters(['lookupParts', 'loadingProgress']),
}
</script>
