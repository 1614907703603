"axios": "^0.18.0",
    "babel-polyfill": "^6.26.0",
<template>
  <v-app>
    <Navbar />
    <v-content>
        <v-alert dismissible
          v-model="success"
          transition="scale-transition"
          type="success">
          {{this.$store.getters.message}}
        </v-alert>

        <!--:value="!!this.$store.getters.errMessage"-->
        <v-alert dismissible
          v-model="error"
          transition="scale-transition"
          type="error" icon="warning">
          {{this.$store.getters.errMessage}}
        </v-alert>

        <router-view></router-view>
      </v-content>

      <v-footer class="pa-3">
<!--        v #  {{process.env.VUE_APP_VERSION}}-->
        <v-spacer></v-spacer>
        <div>&copy; {{ new Date().getFullYear() }}</div>
      </v-footer>
  </v-app>
</template>

<script>
import Navbar from './components/Navbar'

export default {
    components: { Navbar },
    computed: {
        success:{
            get () {
                return this.$store.getters.success
            },
            set (v) {
                this.$store.commit('setSuccess', v)
            }
        },
        error:{
            get () {
                return this.$store.getters.error
            },
            set (v) {
                this.$store.commit('setError', v)
            }
        }
    }

}

</script>

<style lang="scss">
  .pointer {
    cursor: pointer;
  }
</style>
