<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md6>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title><v-icon left>account_circle</v-icon>&nbsp;User Details</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="valid" ref="form" lazy-validation>
              <v-text-field name="customerName"
                            label="Contact Name"
                            v-model="account.customerName"
                            :rules="[rules.required]"
                            counter
                            maxlength="50"
                            >
              </v-text-field>

              <v-text-field name="techCode"
                            label="Technician Code"
                            type="text"
                            v-model="account.techCode"
                            :rules="[rules.required]"
                            counter
                            maxlength="6"
                            >
              </v-text-field>

              <v-text-field name="email"
                            label="Contact Email"
                            type="email"
                            v-model="account.email"
                            :rules="[rules.required, rules.email]"
                            maxlength="100"
                            >
              </v-text-field>

              <v-text-field name="phone"
                            label="Contact Phone"
                            type="text"
                            v-model="account.phone"
                            :rules="[rules.required]"
                            counter
                            maxlength="20"
                            >
              </v-text-field>

              <v-text-field name="ext"
                            label="Contact Ext"
                            type="text"
                            v-model="account.ext"
                            counter
                            maxlength="10"
                            >
              </v-text-field>

              <v-switch
                name="admin"
                v-model="account.admin"
                label="Admin"
              ></v-switch>

            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn text small to="accounts">Back to Accounts</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="onSubmit"
              :disabled="!valid"><v-icon left>save</v-icon>&nbsp;Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import { mapGetters} from 'vuex'
import axios from 'axios'
import { API_URL } from '../../utils/api'

export default {
  created () {
    this.loadUserAccountForm()
  },

  computed: mapGetters(['accounts']),

  data () {
    return {
      valid: false,
       rules: {
          required: value => !!value || 'Required.',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          }
        },
      account: {}
    }
  },
  methods: {

    loadUserAccountForm () {
        if (this.$route.params.account != null){
            this.account = this.$route.params.account
        }
    },

    onSubmit (event) {
    event.preventDefault()

    if (this.$refs.form.validate()) {
        // TODO <AP later>: - dispatch action
        const url = (this.account.id === undefined)?`${API_URL.newAccountApi}` : `${API_URL.accountApi}`
        axios.post( url, this.account)
            .then(response => {
                    const commitPayload = response.data.message;
                    const status = response.data.status;
                    if (status === 'Ok') {
                        this.$store.commit('setMessage', commitPayload)
                    } else {
                        this.$store.commit('setErrMessage', commitPayload)
                    }
                    this.$router.push('/accounts')
                },
                () => {  /*error =>*/
                    const commitPayload = 'Submit Order failed'
                    this.$store.commit('setErrMessage', commitPayload)
                    this.$router.push('/accounts')
                })
    }//eo if (this.$refs.form.validate())
    }
  }
}
</script>
