import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from "vuetify/es5/util/colors";

Vue.use(Vuetify, {
  iconfont: 'md',
})

export default new Vuetify({

  theme: {
    themes: {
      light: {
        primary: '#E53935',
        secondary: '#FFCDD2',
        accent: '#3F51B5',
        error: '#b71c1c',
      },
    },
  }

});

