import Vue from 'vue'
import Router from 'vue-router'
import Lookup from './views/partsRequest/Lookup'
import MainMenu from './views/MainMenu'
import Login from './views/login/Login'
import Logout from './views/login/Logout'
import ForgotPassword from './views/login/ForgotPassword'
import ChangePassword from './views/login/ChangePassword'
import ResetPassword from './views/login/ResetPassword'
import PartsView from './views/partsRequest/PartsView'
import AccountsView from './views/accounts/AccountsView'
import UserAccountForm from './views/accounts/UserAccountForm'
import WarehouseView from './views/partsRequest/WarehouseView'
import WarehousePartOrder from './views/partsRequest/WarehousePartOrder'
import BinlocationsView from './views/binLocations/BinlocationsView'
import SiteView from './views/binLocations/SiteView'

import store from './store'

Vue.use(Router)

const router =  new Router({
  //mode: 'history',
  mode: 'hash',
  base: process.env.VUE_APP_BASE_URL,
  routes: [
    { path: '/', name: 'main', component: MainMenu, meta: { requiresAuth: true, appName: 'Home'}},

    { path: '/lookup', name: 'lookup', component: Lookup, meta: { requiresAuth: true, appName: 'OrderParts'}},
    { path: '/parts', name: 'parts', component: PartsView, meta: { requiresAuth: true, appName: 'OrderParts'} },
    { path: '/warehouse', name: 'warehouse', component: WarehouseView, meta: { requiresAuth: true, appName: 'OrderParts'} },
    { path: '/warehousePartOrder', name: 'warehousePartOrder', component: WarehousePartOrder, meta: { requiresAuth: true, appName: 'OrderParts'} },

    { path: '/accounts', name: 'accounts', component: AccountsView, meta: { requiresAuth: true, appName: 'Accounts'} },
    { path: '/userAccount', name: 'userAccount', component: UserAccountForm, meta: { requiresAuth: true, appName: 'Accounts'} },

    { path: '/sites', name: 'sites', component: SiteView, meta: { requiresAuth: true, appName: 'BinLocation'} },
    { path: '/binlocations', name: 'binlocations', component: BinlocationsView, meta: { requiresAuth: true, appName: 'BinLocation'} },

    { path: '/login', name: 'login', component: Login, meta: { requiresAuth: false, appName: 'Login'} },
    { path: '/logout', name: 'logout', component: Logout, meta: { requiresAuth: false, appName: 'Login'}  },
    { path: '/forgotPassword', name: 'forgotPassword', component: ForgotPassword, meta: { requiresAuth: false, appName: 'Login'}  },
    { path: '/changePassword', name: 'changePassword', component: ChangePassword, meta: { requiresAuth: false, appName: 'Login'}  },
    { path: '/resetPassword/:hashcode', name: 'resetPassword', component: ResetPassword, meta: { requiresAuth: false, appName: 'Login'}  },
    //'catch-all' fallback (DOESN'T work)
    //{ path: '*', name: 'lookup', component: Lookup, meta: { requiresAuth: true, appName: 'OrderParts'} }

  ]
})

// beforeEach hook will check requiresAuth to route to login
router.beforeEach((to, from, next) => {
  const currentUser = store.getters.currentUser
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (requiresAuth && !currentUser) {
    next('login')
  } else {
      store.commit('currentApp', to.meta.appName)
      next()
  }
})

export default router
