// const basePort = '3001'
// const baseURL = `http://localhost:${basePort}`

export const API_URL = {
    doResetPassword:        `doResetPassword`,

    isLoggedIn:             `isLoggedIn`,
    dologinVue:             `user/dologinVue`,
    logout:                 `logout`,
    resetPasswordRequest:   `resetPasswordRequest`,
    changePassword:         `changePassword`,

    accountsApi:            `data/userprofiles.json`,
    accountApi:             `data/updateUserProfile.json`,
    newAccountApi:          `data/addUserProfile`,
    removeUserProfile:      `data/removeUserProfile`,

    partsApi:               `data/partsmasterVue.json`,
    warehouseApi:	        `data/techinvtVue.json`,
    inventoryApi:	        `data/invtnames.json`,
    orderApi:               'addPartsRequestVue.json',

    sitesApi:	        `data/invtnames.json`,
    siteApi:	        `data/binlocations.json`,
    updateBinLocation:  'updateBinLocationVue.json',
    deleteBinLocation:  'removeBinLocationVue.json',

    pprtcd:         'pprtcd',
    pprtn1:         'pprtn1',
    pprtst:         'pprtst',
    warehousePartCodeName: `JPRTCD`
};

