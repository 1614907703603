import axios from 'axios'
import { API_URL } from '../utils/api'
import router from '../router'

export default{
  state: {
    currentUser: null
  },
  getters: {
    currentUser (state) {
      return state.currentUser
    }
  },
  mutations: {
    setCurrentUser (state, user){
      state.currentUser = user
    }
  },
  actions: {
    isLoggedIn (context) {

      axios.get( API_URL.isLoggedIn )
        .then((response) => {
          const user = response.data
          context.commit('setCurrentUser', user)
          return "LoggedIn";
        }
        /*,error => {
          //console.log("updateWarehousePart failed submit error: '" + error + "'")
          // dispatch({ type: FETCH_WAREHOUSE_PARTS_REJECTED, payload: error })
        }*/
        )
    },
    login({commit}, formData){
      axios.post(API_URL.dologinVue, formData)
          .then((response) => {
              const user = response.data;
              commit('setCurrentUser', user)
              if (user !== null && user !== '')
                router.push({name:'main'})
              else{
                // TODO <AP>: - ErrMessage not shown at 2nd failed login
                //commit('setError', true)
                commit('setErrMessage', 'Login attempt failed.')
                router.push({name:'login'})
              }
          }
          //, error => {
              //console.log("login  failed with: " + error + "'")
              // dispatch({type: LOOKUP_PARTS_REJECTED, payload: error})
          //}
          )
    },
    logout({commit}){
      axios.get(API_URL.logout)
          .then(
              (response) => {
                  const message = response.data.message;
                  const status = response.data.status;
                  commit('setCurrentUser', null)
                    if (status === 'Ok') {
                        commit('setMessage', message)
                    } else {
                        commit('setErrMessage', message)
                  }
                  router.push({name:'login'})
              },
              error => {
                commit('setErrMessage', "Something really wrong 8-(" + error)
              })
    },
    resetPasswordInit({commit}, encodedArgument){
        console.log("loginService:resetPasswordInit "+encodedArgument);
    },
    resetPassword({commit}, formData){
      axios.post(API_URL.doResetPassword , formData)
          .then(
              (response) => {
                  //commit('setCurrentUser', response.data)
                  const message = response.data.message;
                  const status = response.data.status;
                    if (status === 'Ok') {
                        commit('setMessage', message)
                    } else {
                        commit('setErrMessage', message)
                  }
                  router.push({name:'login'})
              },
              error => {
                commit('setErrMessage', "Something really wrong 8-(" + error)
              })
    },
    changePassword({commit}, formData){
      axios.post(API_URL.changePassword, formData)
          .then(
              (response) => {
                  const message = response.data.message;
                  const status = response.data.status;
                    if (status === 'Ok') {
                        commit('setMessage', message)
                    } else {
                        commit('setErrMessage', message)
                  }
                  router.push({name:'login'})
              },
              error => {
                commit('setErrMessage', "Something really wrong 8-(" + error)
              })
    },
    forgotPassword({commit}, formData){
      axios.post(API_URL.resetPasswordRequest, formData)
          .then(
              (response) => {
                  const message = response.data.message;
                  const status = response.data.status;
                    if (status === 'Ok') {
                        commit('setMessage', message)
                    } else {
                        commit('setErrMessage', message)
                  }
                  router.push({name:'login'})
              },
              error => {
                commit('setErrMessage', "Something really wrong 8-(" + error)
              })
    }

  }
}
