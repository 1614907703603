<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md6>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Forgot Password</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="valid" ref="form" lazy-validation>
              <v-text-field prepend-icon="person"
                            name="email"
                            label="Email"
                            type="email"
                            v-model="email"
                            required
                            :rules="emailRules">
              </v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn text small to="login">Back to Login</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="onSubmit"
              :disabled="!valid">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  data () {
    return {
      valid: false,
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ], /* v => /.+@.+/.test(v) || 'E-mail must be valid' */
      password: '',
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 6) || 'Password longer then 5 characters'
      ]
    }
  },
  methods: {
    ...mapActions(['forgotPassword']),
    onSubmit () {
      const formData = {
        email: this.email,
      }
      if (this.$refs.form.validate()) {
          this.forgotPassword(formData)
      }
    }
  }
}
</script>
