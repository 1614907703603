<template>
  <v-container fluid fill-height>
  </v-container>
</template>

<script>
import {mapActions} from 'vuex'


export default {
  /* data () {
    return {}
  }, */
  methods: {
    ...mapActions(['logout'])
  },
  created(){
    this.logout()
  }
}
</script>
