<template>
  <div class="about">

    <v-toolbar  dark color="primary">
      <v-toolbar-title><v-icon left>account_box</v-icon>User List</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-text-field
                v-model="search"

                light solo
                clearable=true
                clear-icon="close"
                label="Search Account"

                single-line
                hide-details
      ></v-text-field> <!-- append-outer-icon="search" -->
      <v-icon>search</v-icon>
      <v-btn text @click="openAccount()">
        <v-icon left>person_add</v-icon>
        Add Account
      </v-btn>

    </v-toolbar>


    <v-data-table
      :headers="headers"
      :items="accounts"
      :search="search"
      :loading="loadingProgress"
      class="elevation-1"
      @click:row="openAccount">
      <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
      <template v-slot:item.admin="{ item }">
        <v-simple-checkbox v-model="item.admin" disabled></v-simple-checkbox>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon small class="mr-2" @click.stop="confirmDeleteAccount( item )" color="error" v-on="on">mdi-delete-outline</v-icon>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
    </v-data-table>

      <v-dialog v-model="deleteConfirmationModal" persistent max-width="400px">
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title><v-icon large left>report</v-icon>Confirm operation</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            Do you really want to delete User Account?
          </v-card-text>
          <v-card-actions>
            <v-btn text small @click="deleteConfirmationModal=false">Cancel</v-btn>
            <v-spacer></v-spacer>
              <v-btn color="primary" @click.stop="deleteAccount(); deleteConfirmationModal = false"><v-icon small left>thumb_up</v-icon>Yes</v-btn>
              <!--<v-btn color="primary" @click.stop="deleteConfirmationModal = false;"><v-icon small left>thumb_down</v-icon>No</v-btn>-->
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

  </div>
</template>

<script>
import { mapGetters, mapActions} from 'vuex'
export default {


  created () {
      this.fetchAccounts();
  },


  data () {
    return {
      search: '',
      deleteConfirmationModal: false,
      accountToDelete: null,
      headers: [
        { text: 'Customer', align: 'left', value: 'customerName' },
        { text: 'Technician', align: 'center', value: 'techCode' },
        { text: 'Contact Email', align: 'center', value: 'email' },
        { text: 'Contact Phone', align: 'center', value: 'phone' },
        { text: 'Contact Ext', align: 'center', value: 'ext' },
        { text: 'Admin', align: 'center', value: 'admin' },
        { text: 'Actions', value: "action", sortable: false }
      ]/*,
      accounts: null*/
    }
  },
  methods: {
      ...mapActions(['fetchAccounts', 'fetchAccount', 'removeUserProfile']),
      openAccount( account ){
          //TODO: <AP> - better approach to pass item.partCode to action which will upload fresh copy of warehouse item to order. See TODO in WarehousePartOrder.vue
          this.$router.push({ name: 'userAccount', params: { account:account }})
      },
      confirmDeleteAccount(account){
        this.accountToDelete = account
        this.deleteConfirmationModal = true
      },
      deleteAccount(){
        this.removeUserProfile(this.accountToDelete);
        this.accountToDelete = null;
      }
  },
  computed: mapGetters(['accounts', 'loadingProgress'])


}
</script>
