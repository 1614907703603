<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md6>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Reset Password</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="valid" ref="form" lazy-validation>
              <v-text-field prepend-icon="person"
                            name="email"
                            label="Email"
                            type="email"
                            v-model="email"
                            required
                            :rules="emailRules">
              </v-text-field>
              <v-text-field prepend-icon="vpn_key"
                            name="resetKey"
                            label="Key"
                            v-model="resetKey"
                            :counter="6"
                            required
                            :rules="resetKeyRules">
              </v-text-field>
              <v-text-field prepend-icon="lock"
                            :append-icon="newPasswordShow ? 'visibility' : 'visibility_off'"
                            :type="newPasswordShow ? 'text' : 'password'"
                            @click:append="newPasswordShow = !newPasswordShow"
                            name="newPassword"
                            label="New Password"
                            v-model="newPassword"
                            :counter="6"
                            required
                            :rules="newPasswordRules">
              </v-text-field>
              <v-text-field prepend-icon="lock"
                            :append-icon="confirmPasswordShow ? 'visibility' : 'visibility_off'"
                            :type="confirmPasswordShow ? 'text' : 'password'"
                            @click:append="confirmPasswordShow = !confirmPasswordShow"
                            name="confirmPassword"
                            label="Confirm New Password"
                            v-model="confirmPassword"
                            :counter="6"
                            required
                            :rules="[
                                      v => !!v || 'Confirm Password is required',
                                      v => (v && v.length >= 6) || 'Password longer then 5 characters',
                                      checkConfirmPassword
                                    ]">
              </v-text-field>
              <!-- {{hashcode}} -->
            </v-form>
          </v-card-text>
          <v-card-actions>
            <!-- Back button doesn't work if you came here without proper router initialization
              <v-btn text small to="login">Back to Login</v-btn> -->
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="onSubmit"
              :disabled="!valid">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  created () {
    //TODO take 'hashcode' argument from route parameter `/resetPassword/:hashcode`
    let encodedArgument = this.$route.params.hashcode;
    this.encodedEmail = encodedArgument;
    //TODO TBR console.log("ResetPassword.vue:resetPasswordInit "+encodedArgument)
    this.resetPasswordInit(encodedArgument);
  },
  data () {
    return {
      valid: false,
      encodedEmail: '',
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ], /* v => /.+@.+/.test(v) || 'E-mail must be valid' */
      resetKey: '',
      resetKeyRules: [
        v => !!v || 'Key is required'
      ],
      newPassword: '',
      newPasswordShow:false,
      newPasswordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 6) || 'Password longer than 5 characters'
      ],
      confirmPassword: '',
      confirmPasswordShow:false

      //hashcode: ''

    }
  },
  methods: {
    ...mapActions(['resetPasswordInit', 'resetPassword']),
    onSubmit () {
      const formData = {
        encodedEmail: this.encodedEmail,
        email:    this.email,
        resetKey: this.resetKey,
        password: this.newPassword,
        //hashcode: this.hashcode
      }
      if (this.$refs.form.validate()) {
          this.resetPassword(formData)
      }
    },
    checkConfirmPassword(value){
      if (value !== this.newPassword){
        return 'Password confirmation field should match new password'
      }
      return true;

    }
  }
}
</script>
