import axios from 'axios'
import router from '../router'
import {API_URL} from '../utils/api'

export default {
    state: {
        parts: [],
        warehouseParts: [],
        warehouseList: [], // list of destination warehouse locations
    },
    getters: {
        lookupParts(state) {
            return state.parts
        },
        warehouseParts(state) {
            return state.warehouseParts
        },
        warehouseList(state) {
            return state.warehouseList
        }
    },
    mutations: {
        parts(state, payload) {
            state.parts = payload
        },
        setWarehouseContent(state, payload) {
            state.warehouseParts = payload
        },
        setWarehouseList(state, payload) {
            state.warehouseList = payload
        }
    },
    actions: {
        submitPartOrder({commit}, formData) {
            var headers = {
                'Content-Type': 'application/json'
            }
            axios.post(`${API_URL.orderApi}`, formData, {headers})
                .then(response => {
                        const commitPayload = response.data.message;
                        const status = response.data.status;
                        if (status === 'Ok') {
                            /*this.$store.*/
                            commit('setMessage', commitPayload)
                        } else {
                            /*this.$store.*/
                            commit('setErrMessage', commitPayload)
                        }
                    },
                    () => {  /*error =>*/
                        /*const commitPayload = 'Submit Order failed'*/
                        /*this.$store.*/
                        commit('setErrMessage', 'Submit Order failed')
                    })
        },
        /*
         * read available location codes to fill location combobox
         */
        loadWarehouseLocations({commit}) {
            commit('setLoadingProgress', true)
            axios.get(`${API_URL.inventoryApi}`)
                .then(
                    response => {
                        let inventories = response.data

                        /* Keep sorting if BU want really alphabetical sorting here

                        inventories = inventories.sort((a, b) => {
                            var x = a.locationCode.toLowerCase();
                            var y = b.locationCode.toLowerCase();
                            if (x < y) {return -1;}
                            if (x > y) {return 1;}
                            return 0;
                        });*/


                        let warehouseListLocal = inventories.map((inventory) => {  /*, index*/
                                return {
                                    locationCode: inventory.locationCode,
                                    locationName: `${inventory.locationCode} | ${inventory.locationName}`
                                }
                            }
                        )
                        // direct set 'this.warehouseList = warehouseListLocal' will NOT affect consumer component
                        commit('setLoadingProgress', false)
                        commit('setWarehouseList', warehouseListLocal)
                    },
                    () => {/*error =>*/
                        const commitPayload = 'Failed Load Warehouse Locations'
                        commit('setErrMessage', commitPayload)
                    })
        },
        openOrderForm({commit}, item) {
            if (parseInt(item.currentStockQty) == 0) {
                commit('setErrMessage', 'Part out of stock');
                return
            }
            //TODO: <AP> - better approach to pass item.partCode to action which will upload fresh copy of warehouse item to order. See TODO in WarehousePartOrder.vue
            router.push({name: 'warehousePartOrder', params: {id: item.id}})
        },
        lookupParts({commit}, lookupForm) {
            axios.post(`${API_URL.partsApi}`, lookupForm)
                .then((response) => {
                        commit('parts', response.data)
                        router.push('/parts')
                    }
                )
        },//eof fetchWarehouseParts
        fetchWarehouseParts(context, payload) {
            const partCode = payload
            let apiUrl = API_URL.warehouseApi;
            let formBean = {partCode: partCode};
            context.commit('setLoadingProgress', true)
            axios.post(apiUrl, formBean)
                .then((response) => {
                        // decorate warehouse records with generated id property
                        const commitPayload = response.data.map((entity, id) => {
                            return {id, ...entity}
                        })
                        context.commit('setWarehouseContent', commitPayload)
                        context.commit('setLoadingProgress', false)
                        router.push('warehouse')
                    }
                )
        }//eof fetchWarehouseParts
    }//eof actions
}
