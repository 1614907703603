<template>
  <div>
    <!-- <v-toolbar  dark color="primary">
      <v-toolbar-title><v-icon>location_city</v-icon>&nbsp;Master List</v-toolbar-title>
    </v-toolbar> -->

    <v-container fluid>

      <v-layout column>
        <v-flex xs12>
          <div class='resultContainer'>
            <v-layout align-center justify-center row wrap fill-height>

              <v-card xs12 sm4 md4 lg4 class="gap elevation-12" v-for="menuItem in availableMenuItems" :key="menuItem.title">
                <!-- // TODO <AP later>: - clickable Card image   @click="" -->
                <v-img class="my-3" v-bind:src="menuItem.icon" height="150" width="300" contain></v-img>  <!-- height="150" contain -->
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn v-if="menuItem.link" text small color="primary"  :to="menuItem.link"><v-icon left>{{menuItem.mdnIcon}}</v-icon> {{menuItem.title}}</v-btn>
                  <v-btn v-else               text small color="primary"  :href="menuItem.hrefLink"><v-icon left>{{menuItem.mdnIcon}}</v-icon> {{menuItem.title}}</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>

            </v-layout>
          </div>
        </v-flex>
      </v-layout>

    </v-container>
  </div>
</template>

<style scoped>

  .resultContainer {
    height: 80vh;
  }
  .gap{
    margin: 10px;
  }
</style>

<script>
    import {mapGetters} from 'vuex'
    export default {
      computed: {
          ...mapGetters(['currentUser']),
          availableMenuItems(){
              const availableItems = this.mainMenuItems.filter(record=> {
                  if(this.currentUser.admin)
                      return true
                  else
                      return !record.adminOnly
              })
              return availableItems
          }
      },

      data () {
        return {
          carouselImages: null,
          mainMenuItems: [
              { title: 'My Account', icon: require('@/assets/my-account.png'), mdnIcon: 'account_box', link: '/accounts', adminOnly: true},
              { title: 'Parts Request', icon: require('@/assets/parts-request.png'), mdnIcon: 'location_city', link: '/lookup', adminOnly: false },
              { title: 'Hold Queues', icon: require('@/assets/hold-queues.png'), mdnIcon: 'pan_tool', link: '/sites', adminOnly: true}
          ]
        }
      }

    }
</script>
