import axios from 'axios'
//import router from '../router'
import { API_URL } from '../utils/api'

export default{
    state: {
        accounts : []
    },
    getters: {
        accounts (state) {
            return state.accounts
        }
    },
    mutations: {
        setAccounts (state, payload) {
            state.accounts = payload
        }
    },
    actions: {
        fetchAccounts({commit} ) {
            commit('setLoadingProgress', true)
            axios.get( API_URL.accountsApi )
                .then((response) => {
                        // TODO <AP> later: - remove serializableObjectId from BaseEntity.java
                        const accountsData = response.data.map((entity) => {
                            if (entity.serializableObjectId !== undefined) {
                                delete entity.serializableObjectId;
                            }
                            return { ...entity }
                        })
                        commit('setLoadingProgress', false)
                        commit('setAccounts', accountsData)
                    }
                    /*,error => {
                      //console.log("updateWarehousePart failed submit error: '" + error + "'")
                      // dispatch({ type: FETCH_WAREHOUSE_PARTS_REJECTED, payload: error })
                    }*/
                )
        },//eof fetchAccounts

        removeUserProfile({commit}, account){
            axios.post( API_URL.removeUserProfile, account)
                    .then((response) => {
                        const message = response.data.message;
                        const status = response.data.status;
                        if (status === 'Ok') {
                            commit('setMessage', message)
                        } else {
                            commit('setErrMessage', message)
                        }
                        //router.push({name:'accounts'})
                        this.dispatch('fetchAccounts')

                    }
                    /*,error => {
                    //  console.log("updateWarehousePart failed submit error: '" + error + "'")
                    //  dispatch({ type: FETCH_WAREHOUSE_PARTS_REJECTED, payload: error })
                    }*/
            )
        }
    }//eof actions
}
