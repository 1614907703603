<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md6>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title><v-icon>location_city</v-icon>&nbsp;Order Tech/Warehouse Inventory Part</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="valid" ref="form" lazy-validation>
              <v-text-field name="partCode"
                            label="Parts Code"
                            v-model="warehousePart.partCode"
                            readonly>
              </v-text-field>
              <v-text-field name="partName"
                            label="Parts Name"
                            type="text"
                            :value="warehousePart.partName"
                            readonly>
              </v-text-field>
              <v-text-field name="locationCode"
                            label="Tech/Wrh Code"
                            type="text"
                            v-model="warehousePart.locationCode"
                            readonly>
              </v-text-field>

              <!--<v-text-field name="locationName"
                            label="Warehouse Name"
                            type="text"
                            v-model="warehousePart.locationName"
                            readonly>
              </v-text-field>-->

              <v-text-field name="currentStockQty"
                            label="On-hand"
                            type="text"
                            v-model="warehousePart.currentStockQty"
                            readonly>
              </v-text-field>

              <v-text-field name="autoReplenishIndicator"
                            label="Auto Replenish Ship"
                            type="text"
                            v-model="warehousePart.autoReplenishIndicator"
                            readonly>
              </v-text-field>

              <v-text-field name="standardStockQty"
                            label="Standard Stock Qty"
                            type="text"
                            v-model="warehousePart.standardStockQty"
                            readonly>
              </v-text-field>

              <v-text-field name="qtyRequest"
                            label="Request Qty"
                            type="number"
                            min="1" :max="warehousePart.currentStockQty"
                            v-model="qtyRequest"
                            :rules="[
                                   (v) => !!v || 'Requested parts quantity is required',
                                  checkRequestNumber
                            ]"
              >
              </v-text-field>

              <v-select label="Expect Priority" :items="expectPriorityOptions" v-model="expectPriority"></v-select>

              <v-text-field name="locationCode"
                            label="Tech/Wrh From"
                            type="text"
                            v-model="warehousePart.locationCode"
                            readonly>
              </v-text-field>

              <!--<v-select item-text="locationName"
                        item-value="locationCode"
                        name="warehouseTo"
                        label="Tech/Wrh To"
                        type="text"
                        :items="warehouseList"
                        v-model="warehouseTo"
                        :rules="[
                                  () => !!warehouseTo || 'This field is required'
                                ]"
              >
              </v-select>-->

              <v-autocomplete item-text="locationName"
                        item-value="locationCode"
                        name="warehouseTo"
                        label="Tech/Wrh To"
                        type="text"
                        :items="warehouseList"
                        v-model="warehouseTo"
                        :rules="[
                                  () => !!warehouseTo || 'This field is required'
                                ]"
              >
              </v-autocomplete>
              <v-textarea name="expectComment"
                          label="Comments"
                          hint="Enter comments here"
                          v-model="expectComment"
                          maxlength="150"
                          :rules="[
                                   (v) => v.length < 150 || 'Max 150 characters allowed in comments'
                            ]">
              </v-textarea>

              <v-flex xs12 sm12 md12>
                <v-menu
                        v-model="expectDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                            v-model="expectDate"
                            label="Expect Date"
                            prepend-icon="event"
                            readonly
                            v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="expectDate" @input="expectDateMenu = false"></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm12 md12>
                <!--<v-menu
                  ref="timePickerMenu"
                  :close-on-content-click="false"
                  v-model="expectTimeMenu"
                  :nudge-right="40"
                  :return-value.sync="expectTime"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <v-text-field
                    slot="activator"
                    v-model="expectTime"
                    label="Expect Time"
                    prepend-icon="access_time"
                    readonly
                  ></v-text-field>
                  <v-time-picker
                    v-if="expectTimeMenu"
                    v-model="expectTime"
                    full-width
                    @change="$refs.timePickerMenu.save(expectTime)"
                  ></v-time-picker>
                </v-menu>-->

                <v-menu
                        ref="menu"
                        v-model="expectTimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="expectTime"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                            v-model="expectTime"
                            label="Expect Time"
                            prepend-icon="access_time"
                            readonly
                            v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                          v-if="expectTimeMenu"
                          v-model="expectTime"
                          full-width
                          @click:minute="$refs.menu.save(expectTime)"
                  ></v-time-picker>
                </v-menu>

              </v-flex>
<!--end of time picker-->
              <v-text-field name="invFlag"
                            label="Tech/Wrh"
                            v-model="warehousePart.invFlag"
                            readonly>
              </v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn text small to="warehouse">Back to Warehouse</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="onSubmit"
              :disabled="!valid"><v-icon>add_shopping_cart</v-icon>&nbsp;Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {

    created () {
      this.loadWarehouseLocationsDropDown()
      this.loadWarehouseOrderForm()
    },

    computed: mapGetters(['warehouseList']),

    data () {
      return {
        valid: false,

        qtyRequest: 1,
        expectPriorityOptions: ['HIGH', 'MEDIUM', 'LOW'],
        expectPriority: 'MEDIUM',

        expectComment: '',

        expectDate: new Date().toISOString().substr(0, 10),
        expectDateMenu: false,

        expectTime: '',
        expectTimeMenu: false,

        warehouseTo: '',
        warehousePart: {}

      }
    },
    methods: {
      ...mapActions(['loadWarehouseLocations', 'submitPartOrder']),
      loadWarehouseLocationsDropDown(){
        this.loadWarehouseLocations()
      },
      checkRequestNumber( value ){
          //Do we really need to handle this here if previous rule should take care of empty value?
          if (value ===null ||value.length === 0) {
              return 'Requested parts quantity is required'
          }

          const qtyRequest  = parseInt(value)
          if (qtyRequest > this.warehousePart.currentStockQty) {
              return "Number of requested parts can't exceed warehouse part's on hand";
          } else {
              return true;
          }

      },

      /*
      * TODO: <AP> Probably WarehouseOrderForm should be loaded by request to reduce lag between retrieval and ordering
      */
      loadWarehouseOrderForm () {
        //console.log('loadWarehouseOrderForm with id = ' + this.$route.params.id)
        let id = this.$route.params.id
        let warehouseParts = this.$store.getters.warehouseParts
        let findWarehouseParts = warehouseParts.filter(record => record.id === id) // use `==` because this.props.match.params.tableId is a String while part.id is numeric
        // we probably should not worry about empty filter result because match pair (part.id, id) guarantied to exist (it's filled in WarehouseList)
        this.warehousePart = findWarehouseParts[0]
      },


      onSubmit (event) {
        event.preventDefault()

        const formData = {
          partCode: this.warehousePart.partCode,
          partDesc: this.warehousePart.partName,
          warehouseFrom: this.warehousePart.locationCode,
          warehouseTo: this.warehouseTo,
          qtyOnhand: this.warehousePart.currentStockQty,
          qtyRequest: parseInt(this.qtyRequest),
          expectPriority: this.expectPriority,
          expectComment: this.expectComment,
          expectDate: this.expectDate,
          expectTime: this.expectTime,
          invFlag: this.warehousePart.invFlag
        }

        if (this.$refs.form.validate()) {
            //To avoid double submission just push router away from submitted form first and than do send ajax
            this.$router.push('/parts')
            this.submitPartOrder(formData);
        }//eo if (this.$refs.form.validate())
      }
    }
}
</script>
