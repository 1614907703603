<template>
  <div class="about">

    <v-toolbar  dark color="primary">
      <v-toolbar-title><v-icon left>location_city</v-icon>Site List</v-toolbar-title>
      <v-spacer></v-spacer>
        <v-text-field
                v-model="search"

                light solo
                clearable=true
                clear-icon="close"
                label="Search Site by code or name"

                single-line
                hide-details
        ></v-text-field> <!-- append-outer-icon="search" -->
        <v-icon>search</v-icon>
      <v-btn text @click="getSpreadsheetTemplate(siteLocationCode)">
        <v-icon left>cloud_download</v-icon>
        excel
      </v-btn>
    </v-toolbar>


    <v-data-table
      :headers="headers"
      :items="sites"
      :search="search"
      :loading="loadingProgress"
      class="elevation-1"
      @click:row="openSite">
      <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions} from 'vuex'

export default {


  created () {
      this.fetchSites();
  },


  data () {
    return {
      search: '',
      headers: [
        { text: 'Site Code', align: 'left', value: 'locationCode' },
        { text: 'Site Name', align: 'center', value: 'locationName' }
      ]
    }
  },
  methods: {
      ...mapActions(['fetchSites', 'fetchSite']),
      openSite( site ){
          this.fetchSite(site.locationCode); //theStore.load({params:{'locationCode':code}});
          //this.$router.push({ name: 'userAccount', params: { account:account }})
      },
      getSpreadsheetTemplate: function(){
          var hiddenIFrameID = 'hiddenDownloader',
              iframe = document.getElementById(hiddenIFrameID);
          if (iframe === null) {
              iframe = document.createElement('iframe');
              iframe.id = hiddenIFrameID;
              iframe.style.display = 'none';
              document.body.appendChild(iframe);
          }
          iframe.src = 'data/excel/binlocations';
      }
  },
  computed: mapGetters(['sites', 'loadingProgress'])
}
</script>
