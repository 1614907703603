import axios from 'axios'
import router from '../router'
import { API_URL } from '../utils/api'

export default{
    state: {
        sites : [],
        binlocations : []
    },
    getters: {
        sites (state) {
            return state.sites
        },
        binlocations (state) {
            return state.binlocations
        }
    },
    mutations: {
        setSites (state, payload) {
            state.sites = payload
        },
        setBinlocations (state, payload) {
            state.binlocations= payload
        }
    },
    actions: {
        fetchSites({commit} ) {
            commit('setLoadingProgress', true)
            axios.get( API_URL.sitesApi )
                .then((response) => {
                        // TODO <AP> later: - remove serializableObjectId from BaseEntity.java
                        const sites = response.data
                        commit('setSites', sites)
                        commit('setLoadingProgress', false)
                    }
                    /*,error => {
                      //console.log("updateWarehousePart failed submit error: '" + error + "'")
                      // dispatch({ type: FETCH_WAREHOUSE_PARTS_REJECTED, payload: error })
                    }*/
                )
        }//eof fetchSites
        , fetchSite({commit}, locationCode ) {
            commit('setLoadingProgress', true)
            const lCode = locationCode.trim()
            axios.get( API_URL.siteApi+'?locationCode='+ lCode)  //?locationCode=ZAON
                .then((response) => {
                        const errorMsg = response.data.errorMessage
                        commit('setLoadingProgress', false)
                        if(errorMsg != null && errorMsg !==undefined && errorMsg !== ''){
                            commit('setErrMessage', errorMsg)
                        } else {
                            commit('setBinlocations', response.data.locations)
                            router.push({name:'binlocations', params: { locationCode:lCode }})
                        }
                    }
                    /*,error => {
                      //console.log("updateWarehousePart failed submit error: '" + error + "'")
                      // dispatch({ type: FETCH_WAREHOUSE_PARTS_REJECTED, payload: error })
                    }*/
                )
        }, //eof fetchSite
        deleteBinLocation({commit}, payload){
            commit('setLoadingProgress', true)
            axios.post( API_URL.deleteBinLocation, payload.binLocation)
            .then((response) => {
                const message = response.data.message;
                const status = response.data.status;
                commit('setLoadingProgress', false);
                if (status === 'Ok') {
                    commit('setMessage', message)
                    // WRONG fetchSite expect String: this.dispatch('fetchSite', {siteLocationCode: payload.siteLocationCode})
                    this.dispatch('fetchSite', payload.siteLocationCode)
                } else {
                    commit('setErrMessage', message)
                }
            }
            /*,error => {
            //  console.log("updateWarehousePart failed submit error: '" + error + "'")
            //  dispatch({ type: FETCH_WAREHOUSE_PARTS_REJECTED, payload: error })
            }*/
            )
        },
        updateBinLocation({commit}, payload){
            commit('setLoadingProgress', true)
            //const url = API_URL.updateBinLocation
            axios.post( API_URL.updateBinLocation, payload.form)
                .then(response => {
                        const commitPayload = response.data.message;
                        const status = response.data.status;
                        if (status === 'Ok') {
                            commit('setMessage', commitPayload)
                        } else {
                            commit('setErrMessage', commitPayload)
                        }
                        this.dispatch('fetchSite', payload.form.locationCode);
                        commit('setLoadingProgress', false);
                    },
                    () => {  /*error =>*/
                        const commitPayload = 'Submit Order failed'
                        commit('setErrMessage', commitPayload)
                    })
        }

    }//eof actions
}
