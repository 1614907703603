import 'babel-polyfill'
import Vue from 'vue'
import vuetify from './plugins/vuetify';
import App from './App.vue'
import router from './router'
import store from './store/'
//import { API_URL } from './utils/api'
//import axios from 'axios'

//import colors from 'vuetify/es5/util/colors'

/*
TODO - <AP> - attempt to import here fails
import "./assets/font/Gotham/gothamFont.css";
import "./assets/font/material-design-icons/iconfont/material-icons.css";
*/

Vue.config.productionTip = false


/* console.log("router: " + router.currentRoute);

const apiUrl = API_URL.isLoggedIn;
axios.get( apiUrl )
  .then(

    response => {
    if (response.data.status === 'Ok'){
      store.commit('setCurrentUser', response.data)
    } */

    new Vue({

      router,
      store,
      vuetify,
      data () {
        return {
          info: null,
          loading: true,
          errored: false
        }
      },
      render: h => h(App)
    }).$mount('#app')

  //}) // eof response =>
  /* .catch(error => {
    console.log(error)
  }) */
  //.finally(() => this.loading = false)
