<template>
  <div class="about">

    <v-toolbar  dark color="primary">
      <v-toolbar-title><v-icon>location_city</v-icon>&nbsp;Tech/Warehouse Inventory List</v-toolbar-title>
    </v-toolbar>
    <h1 v-if="warehouseParts.length===0"> warehouse is empty</h1>
    <div v-else>
      <v-data-table
        :headers="headers"
        :items="warehouseParts"
        :loading="loadingProgress"
        class="elevation-1"
        @click:row="openOrderForm2">
        <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
        <!-- TODO need to fid out haow to dusable/enable row in new v-data-table
          <tr :class="(props.item.currentStockQty == 0) ? 'disabledRow' : 'enabledRow'">
        -->
      </v-data-table>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  data () {
    return {

      headers: [
        { text: 'Part Code', align: 'center', value: 'partCode' },
        { text: 'Tech/Wrh Code', align: 'center', value: 'locationCode' },
        { text: 'Tech/Wrh Name', align: 'center', value: 'locationName' },
        { text: 'On-Hand', align: 'center', value: 'currentStockQty' },
        { text: 'Bin Location', align: 'center', value: 'binLocation' },
        { text: 'Auto Replenish/Ship', align: 'center', value: 'autoReplenishIndicator' },
        { text: 'Standard/Committed Qty', align: 'center', value: 'standardStockQty' },
        { text: 'Tech/Wrh', align: 'center', value: 'invFlag' }
      ]
    }
  },

  methods: {
      ...mapActions(['openOrderForm']),
    openOrderForm2(item){
        this.openOrderForm(item)
    }
  },
  computed: mapGetters(['warehouseParts', 'loadingProgress']),
}
</script>

<style scoped>

.disabledRow  {
  background-color : gainsboro;
  cursor: auto;
}

.enabledRow  {
  background-color : #c1f8b9;
  cursor: pointer;
}

</style>
