<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md6>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Login</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="valid" ref="form" lazy-validation>
              <v-text-field prepend-icon="person"
                            name="email"
                            label="Email"
                            type="email"
                            v-model="email"
                            required
                            :rules="emailRules">
              </v-text-field>
              <v-text-field prepend-icon="lock"
                            name="password"
                            :append-icon="passwordShow ? 'visibility' : 'visibility_off'"
                            :type="passwordShow ? 'text' : 'password'"
                            @click:append="passwordShow = !passwordShow"
                            label="Password"
                            v-model="password"
                            required
                            :rules="passwordRules">
              </v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <div>
              <v-btn text small to="forgotPassword">Forgot Password?</v-btn><br>
              <v-btn text small to="changePassword">Change Password?</v-btn>
            </div>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="onSubmit"
              :disabled="!valid">Login</v-btn>

          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {mapActions} from 'vuex'
//import axios from 'axios'

export default {
  data () {
    return {
      valid: false,
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ], /* v => /.+@.+/.test(v) || 'E-mail must be valid' */
      password: '',
      passwordShow:false,
      passwordRules: [
        v => !!v || 'Password is required'
      ]
    }
  },
  methods: {
    ...mapActions(['login']),
    onSubmit () {
      const formData = {
        email: this.email,
        password: this.password
      }
      if (this.$refs.form.validate()) {
          this.login(formData)
      }
    }
  }
}
</script>
