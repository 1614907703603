import Vue from 'vue'
import Vuex from 'vuex'
import loginService from './loginService'
import accountService  from './accountService'
import orderPartsService from  './orderPartsService'
import siteService from  './siteService'

Vue.use(Vuex)

export default new Vuex.Store({

  modules: {
    loginService,
    accountService,
    orderPartsService,
    siteService
  },
  state: {
    currentApp: ``,
    error: false,
    success: false,
    errMessage: null,
    message: null,

    loadingProgress: false,
  },
  getters: {
    currentApp:(state) => state.currentApp,
    errMessage: (state) => state.errMessage,
    message: (state) => state.message,
    error: (state) => state.error,
    success: (state) => state.success,
    loadingProgress: (state) => state.loadingProgress
  },
  mutations: {
    currentApp: (state, appName) => state.currentApp = appName,
    setErrMessage: (state, payload) => {
      state.error = true
      state.errMessage = payload
    },
    setMessage: (state, payload) => {
      state.success = true
      state.message = payload
    },
    setError: (state, payload) => state.error = payload,
    setSuccess: (state, payload) => state.success = payload,
    setLoadingProgress: (state, payload) => state.loadingProgress = payload
  },
  actions: {
  }
})
