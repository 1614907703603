<template>
    <nav>

    <v-navigation-drawer
        app
        temporary
        v-model="drawerOn">

        <v-toolbar text>
          <v-list>
            <v-list-item>
              <v-list-item-title class="title">
                PPSG Portal
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-toolbar>

        <v-divider></v-divider>

        <v-list dense class="pt-0">

          <v-list-item
            :key="navigationHome.title"
            :to="navigationHome.link">

            <v-list-item-action>
                <v-icon>{{ navigationHome.icon }}</v-icon>
            </v-list-item-action>

            <v-list-item-content>
                <v-list-item-title v-text="navigationHome.title"></v-list-item-title>
            </v-list-item-content>

          </v-list-item>

          <v-list-item
            v-for="item in activeNavigationItems"
            :key="item.title"
            :to="item.link"
            :disabled="item.checker">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>

          </v-list-item>
        </v-list>
      </v-navigation-drawer>


        <!-- This is toolbar (not side drawer)-->
        <v-app-bar app> <!--dark color="primary" -->
        <v-app-bar-nav-icon @click="drawerOn = !drawerOn" class="hidden-md-and-up custom-test"></v-app-bar-nav-icon>
        <!-- <v-toolbar-side-icon  @click="drawerOn = !drawerOn"    class="hidden-md-and-up custom-test"></v-toolbar-side-icon> -->
        <v-toolbar-title>
            <img alt="Canon logo" src='../assets/canon_main_logo.gif' style="margin-bottom: -1rem"/>PPSG Portal
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items class="hidden-sm-and-down">

            <v-btn text :key="navigationHome.title"
                :to="navigationHome.link">
            <v-icon left>{{navigationHome.icon}}</v-icon>
            {{navigationHome.title}}
            </v-btn>


            <v-btn text :key="item.title"
                v-for="item in activeNavigationItems"
                :to="item.link"
                :disabled="item.checker"
            >
                <v-icon left>{{item.icon}}</v-icon>{{item.title}}
            </v-btn>

        </v-toolbar-items>
        </v-app-bar>

    </nav>
</template>

<script>

import {mapGetters} from 'vuex'

export default {
    data() {
    return {
            drawerOn: false,
            navigationHome:{ title: 'Home', icon: 'home', link: '/', show: true }
            }
    },
    computed:{
        ...mapGetters(['lookupParts', 'warehouseParts', 'currentUser', 'currentApp', 'sites', 'binlocations']),
        activeNavigationItems(){
            return this.navigationItems.filter(record => (record.show))
        },
        navigationItems(){return [
            { title: 'Lookup', icon: 'search', link: '/lookup', show: this.currentApp === 'OrderParts', checker: false },
            { title: 'Parts', icon: 'view_list', link: '/parts', show: this.currentApp === 'OrderParts', checker: this.lookupParts.length === 0 },
            { title: 'Warehouse', icon: 'location_city', link: '/warehouse', show: this.currentApp === 'OrderParts', checker: this.warehouseParts.length === 0 },

            { title: 'Sites', icon: 'location_city', link: '/sites', show: this.currentApp === 'BinLocation', checker: this.sites.length  === 0 },
            { title: 'BinLocation', icon: 'domain', link: '/binlocations', show: this.currentApp === 'BinLocation', checker: this.binlocations.length  === 0 },

            { title: 'Logout', icon: 'exit_to_app', link: '/logout', show:true, checker: this.currentUser  === null }
        ]}
    },
    methods:{
    }
}
</script>
