<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md6>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title><v-icon>search</v-icon>&nbsp;Lookup</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="valid" ref="form" lazy-validation>
              <v-text-field prepend-icon="code"
                            name="pprtcd"
                            label="Parts Code"
                            type="text"
                            @keyup.enter.native="onSubmit"
                            v-model="pprtcd">
              </v-text-field>
              <v-text-field prepend-icon="description"
                            name="pprtn1"
                            label="Parts Name"
                            type="text"
                            @keyup.enter.native="onSubmit"
                            v-model="pprtn1">
              </v-text-field>
              <!--<v-text-field prepend-icon="code"
                            name="pprtst"
                            label="Vendor Parts Code"
                            type="text"
                            @keyup.enter.native="onSubmit"
                            v-model="pprtst">
              </v-text-field>-->
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="onSubmit"
              :disabled="!valid"><v-icon left>search</v-icon>Lookup</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  data () {
    return {
      valid: false,
      pprtcd: '',
      pprtn1: '',
      pprtst: ''
    }
  },
  methods: {
    ...mapActions(['lookupParts']),
    onSubmit () {
      const lookupForm = {
          pprtcd: this.pprtcd,
          pprtn1: this.pprtn1,
          pprtst: this.pprtst
      }
      this.lookupParts(lookupForm);
    }
  }
}
</script>
